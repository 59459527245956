<template>
  <!-- 设备异常 -->
  <div style="margin-top: 20px;">
    <div class="box-card" style="text-align: left">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="停车场：">
          <el-select popper-class="my-select" clearable v-model="queryForm.parking_id" filterable default-first-option
            placeholder="请选择停车场" @change="onSubmit">
            <el-option v-for="item in parkingList" :key="item.parking_id" :label="item.parking_name"
              :value="item.parking_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称：">
          <el-input maxlength="30" placeholder="请输入设备名称" v-model="queryForm.device_name"
            @input="(e) => (queryForm.device_name = validSpecialChar(e))" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>
        <el-form-item label="告警类型：">
          <el-select popper-class="my-select" clearable v-model="queryForm.type" placeholder="请选择告警类型" @change="onSubmit">
            <el-option v-for="item in $store.getters.getOptions('AlarmType')" :key="item.value" :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="告警时间：">
          <el-date-picker v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
            @blur="onSubmit" type="datetimerange" range-separator="至" start-placeholder="请选择开始日期"
            end-placeholder="请选择结束日期">
          </el-date-picker>
          <!-- <el-date-picker v-model="queryForm.start_create_time" type="datetime" placeholder="请选择开始时间">
            </el-date-picker>
            <span> ~ </span>
            <el-date-picker v-model="queryForm.end_create_time" type="datetime" placeholder="请选择结束时间">
            </el-date-picker> -->
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="设备异常">
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange" @current-change="currentChange">
            <template slot-scope="{row}" slot="deal_with_time">
              <span>{{ validDateTime(row.create_time) }}</span>
            </template>
            <template slot-scope="{row}" slot="is_deal_with">
              <span :style="row.is_deal_with ? '' : 'color:#ee0000;'">{{
                row.is_deal_with ? '已处理' : '待处理'
              }}</span>
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="err" icon="daichuli" hint="处理" @click="handleit(row)"
                v-if="!row.is_deal_with && isShowBtn('3c785afbeba149f0af623e81')"></lbButton>
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookingfor(row)" v-if="row.is_deal_with"></lbButton>
            </template>
          </avue-crud> -->
          <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
            tooltip-effect="dark" border stripe>
            <el-table-column prop="device_type_name" label="设备类型">
            </el-table-column>
            <el-table-column prop="device_name" label="设备名称">
            </el-table-column>
            <el-table-column prop="parking_name" label="停车场"></el-table-column>
            <el-table-column prop="alarm_type_name" label="告警类型">
            </el-table-column>
            <el-table-column prop="deal_with_time" label="告警时间">
              <template slot-scope="scope">
                <span>{{ validDateTime(scope.row.create_time) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="is_deal_with" label="状态">
              <template slot-scope="scope">
                <span :style="scope.row.is_deal_with ? '' : 'color:#ee0000;'">{{
                  scope.row.is_deal_with ? '已处理' : '待处理'
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <lbButton type="err" icon="daichuli" hint="处理" @click="handleit(scope.row)"
                  v-if="!scope.row.is_deal_with && isShowBtn('3c785afbeba149f0af623e81')"></lbButton>
                <lbButton type="succes" icon="chakan" hint="查看" @click="lookingfor(scope.row)"
                  v-if="scope.row.is_deal_with"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="TotalCount">
          </el-pagination>
        </div>
      </le-card>
    </div>
    <lebo-dialog append-to-body :title="DialogVisibleTitle" :isShow="centerDialogVisible" width="50%"
      @close="centerDialogVisible = false" footerSlot>
      <div class="dialog_box">
        <div class="title_top">
          <span>告警记录:</span>
          <span>共{{ deviceList.length }}条</span>
        </div>
        <el-table :data="deviceList" border stripe class="detail_table">
          <el-table-column label="设备类型">
            <template slot-scope="scope">
              {{ getWayName('DeviceType', scope.row.device_type) }}
            </template>
          </el-table-column>
          <el-table-column prop="device_name" label="设备名称">
          </el-table-column>
          <el-table-column prop="alarm_msg" label="告警类型">
          </el-table-column>
          <el-table-column prop="deal_with_time" label="告警时间">
            <template slot-scope="scope">
              <span>{{ validDateTime(scope.row.create_time) }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="detailInfo.is_deal_with" class="deal_box" style="text-align: left;">
          <el-form label-width="90px" :model="detailInfo">
            <el-form-item label="处理人：" style="margin:0px;">
              <span>{{ detailInfo.deal_with_name }}</span>
            </el-form-item>
            <el-form-item label="处理时间：" style="margin:0px;">
              <span>{{ validDateTime(detailInfo.deal_with_time) }}</span>
            </el-form-item>
            <el-form-item label="处理结果：" style="margin:0px;">
              <span>{{ detailInfo.deal_with_msg }}</span>
            </el-form-item>
          </el-form>
        </div>
        <div v-else>
          <div class="title_top">
            <span>处理结果:</span>
          </div>
          <el-input type="textarea" :rows="4" maxlength="100" placeholder="请详细描述....." v-model="handleform.deal_with_msg"
            @input="(e) => (handleform.deal_with_msg = validSpecialChar(e))">
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="centerDialogVisible = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="sureit()">保 存</lbButton>
      </span>
    </lebo-dialog>
  </div>
</template>

<script>
import { handleInfo, getDeviceAlarmDetail, warnigList } from '@/api/administrative'
import { mapState, mapGetters } from 'vuex'
export default {
  data () {
    return {
      // 查询表单
      queryForm: {
        parking_id: null,
        device_name: '',
        alarm_type: null,
        start_create_time: '',
        end_create_time: '',
        PageSize: 10,
        PageIndex: 1
      },
      timeduan: '',
      // 表格数据
      tableData: [],
      options: [
        {
          value: '选项1',
          label: '全部'
        },
        {
          value: '选项2',
          label: '设备状态'
        }
      ],
      centerDialogVisible: false,
      DialogVisibleTitle: '',
      handleform: {
        deal_with_msg: '',
        _id: ''
      },
      TotalCount: 0,
      detailInfo: {},
      deviceList: [],
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '设备类型',
            prop: 'device_type_name'
          }, {
            label: '设备名称',
            prop: 'device_name'
          }, {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '告警类型',
            prop: 'alarm_type_name'
          }, {
            label: '告警时间',
            prop: 'deal_with_time',
            slot: true
          }, {
            label: '状态',
            prop: 'is_deal_with',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  computed: {
    ...mapGetters(['getOptions', 'getWayName']),
    ...mapState(['parkingList'])
  },
  created () { },
  watch: {
    centerDialogVisible (newValue, oldValue) {
      if (!newValue) {
        this.handleform.deal_with_msg = ''
      }
    },
    'timeduan' (newV, oldV) {
      if (newV) {
        this.queryForm.start_create_time = newV[0]
        this.queryForm.end_create_time = newV[1]
      } else {
        this.queryForm.start_create_time = ''
        this.queryForm.end_create_time = ''
        this.onSubmit()
      }
    }
  },
  mounted () {
    this.requestWarningList()
  },
  methods: {
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.requestWarningList()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.requestWarningList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.requestWarningList()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.requestWarningList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.requestWarningList()
    // },
    // 处理
    handleit (row) {
      this.centerDialogVisible = true
      this.DialogVisibleTitle = '异常处理'
      this.fngetDeviceAlarmDetail(row._id)
      this.handleform._id = row._id
    },
    async sureit () {
      if (this.DialogVisibleTitle === '异常处理') {
        if (this.handleform.deal_with_msg.length === 0) {
          this.$msg.warning('处理信息不能为空！')
          return
        }
        const res = await handleInfo(this.handleform)
        if (res && res.Code === 200) {
          this.$msg.success(res.Message)
          this.centerDialogVisible = false
          this.requestWarningList()
        }
      } else {
        this.centerDialogVisible = false
      }
    },
    lookingfor (row) {
      this.DialogVisibleTitle = '详情'
      this.centerDialogVisible = true
      this.fngetDeviceAlarmDetail(row._id)
      // this.$alert(
      //   `${row.deal_with_msg ? row.deal_with_msg : "/"}`,
      //   "处理结果",
      //   {
      //     confirmButtonText: "关闭",
      //   }
      // );
    },
    // 获取设备告警详情
    async fngetDeviceAlarmDetail (id) {
      const res = await getDeviceAlarmDetail({
        _id: id
      })
      // console.log(res);
      if (res && res.Code === 200) {
        this.detailInfo = res.Data
        this.deviceList = res.Data.device
      } else {
        this.detailInfo = {}
      }
    },
    async requestWarningList () {
      try {
        const res = await warnigList(this.queryForm)
        this.tableData = res.Data.DataList
        this.TotalCount = res.Data.TotalCount
        this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      } catch (error) { }
    }
  }
}
</script>

<style scoped lang="less">
.button_box {
  display: flex;
}

.button_box .el-button {
  margin-left: 15px;
  width: auto;
}

.el-dialog__body {
  padding: 20px;
}

.el-dialog {
  /deep/ .el-dialog--center.el-dialog__footer {
    text-align: right;
  }
}

// .el-button {
//   width: auto !important;
// }

.dialog_box {
  .title_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    font-size: 16px;
    color: #000;
  }

  /deep/ .detail_table {
    margin-bottom: 0;

    .el-table__body-wrapper {
      max-height: 250px;
      overflow-y: auto;
    }
  }
}
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-date-editor{
  width: 350px;
}
</style>
